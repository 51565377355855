
const checkForChat = function() {
    let t = setTimeout(checkForChat, 100)
    if(typeof(zE) !== 'undefined') {
        zE('messenger', 'open')
        clearTimeout(t)
    }
}

const checkForChatHash = function() {
    if(window.location.hash === '#chat') {
        checkForChat()
    }    
}

const hideChat = function() {
  let t = setTimeout(hideChat, 100)
    if(typeof(zE) !== 'undefined' && document.querySelector('[data-nochat]')) {
        zE('messenger', 'hide')
        clearTimeout(t)
    }
}

checkForChatHash()
hideChat()

window.addEventListener('hashchange', () => { checkForChatHash() })

// open chat via postMessage from iframes
window.addEventListener('message', message => {
  const whitelist = [
    'https://search.zero.health',
    'https://search.stg.zero.health',
    'https://search.thezerocard.com',
  ]

  if (!whitelist.includes(message.origin)) {
    throw new Error(`Restricted origin attempted to postMessage: ${message.origin}`)
  }

  if (message.data === 'openChat') {
    if (!zE) {
      console.debug('Chat instance not defined.', zE)
    } else {
      zE('messenger', 'open')
    }
  }
})
