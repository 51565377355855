/* -- 
    Attributes
    name: svg file name without extension
    animate: run default animation
    color: fill color of icon
    circle: enclose in circle, set value for color
    size: width & height, e.g. 40px or 4rem
    scroll-triggered: will animate once in viewport
    delay: sets scroll trigger animation delay, use milliseconds integer
--*/

import { scrollTriggering } from "../scripts/modules/scroll-triggering"

class GlyphIcons extends HTMLElement {
    constructor() {
        super().attachShadow({mode: 'open'}).innerHTML = this.render()
        this.setAttribute('role', 'presentation')
        this.loadAsset()
    }

    async loadAsset() {
        const path = '/wp-content/themes/ZERO/images/glyph-icons/'
        const file = `${this.getAttribute('name')}.svg`
        
        const res = await fetch(path + file)
        if(!res.ok) return
        const svg = await res.text()

        this.shadowRoot.querySelector('[data-circle]').innerHTML = svg
        this.shadowRoot.querySelector('svg').classList.add('icon')

        if(this.hasAttribute('scroll-triggered')) this.scroll()
    }

    render() {
        return String.raw`
            <style>
                * {
                    box-sizing: border-box;
                }

                :host {
                    --size: ${(this.hasAttribute('size')) ? this.getAttribute('size') : '100%'};
                    aspect-ratio: 1;
                    display: inline-block;
                    position: relative;
                    width: ${this.getAttribute('size') ?? '5rem'};
                }

                /* Scroll Triggered Styles */

                :host([scroll-triggered]) .circle,
                :host([scroll-triggered]) .icon  {
                    opacity: 0;
                    visibility: visible;
                }

                /* Base Styles */
                .circle {
                    inset: 0 0;
                    position: absolute;
                }

                .icon {
                    display: block;
                    max-width: var(--size);
                    width: 100%;
                }

                .icon * {
                    fill: ${(this.hasAttribute('color')) ? this.getAttribute('color') : 'white'};
                }

                /* Circle Styles */

                :host([circle]) .circle {
                    /* aspect-ratio: 1; */
                    background-color: ${(this.hasAttribute('circle') && this.getAttribute('circle') !== '') ? this.getAttribute('circle') : 'var(--color-2)'};
                    border-radius: 100vmax;
                    display: inline-grid;
                    height: auto;
                    place-items: center;
                }

                :host([circle]) .icon {
                    transform: scale(0.6);
                }

                /* Key Frames */

                @keyframes circleIn {
                    0% {
                        opacity: 0.3;
                        scale: 0.6;
                    }

                    100% {
                        opacity: 1;
                        scale: 1;
                    }
                }

                @keyframes fadePop {
                    0% { 
                        opacity: 0;
                        scale: 0.4; 
                    }

                    100% { 
                        opacity: 1;
                        scale: 1;
                    }
                }

                /* Animate Styles */

                :host([animate][circle]) .circle {
                    animation: circleIn 500ms ease;
                    animation-fill-mode: both;
                    opacity: 0;
                    transform-box: fill-box;
                    transform-origin: center;
                }

                :host([animate]) .icon {
                    animation-name: fadePop;
                    animation-duration: 500ms;
                    animation-delay: 200ms;
                    animation-fill-mode: both;
                    animation-iteration-count: ${(this.hasAttribute('repeat')) ? 'infinite' : 1};
                    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    transform-box: fill-box;
                    transform-origin: center;
                }
            </style>

            <span class="circle" data-circle></span>
        `
    }

    scroll() {
        scrollTriggering({
            elem: this,
            in: (entry) => {
                setTimeout(() => entry.target.setAttribute('animate', true), this.getAttribute('delay') ?? 0)
            }
        })
    }
}

customElements.define('zero-icon', GlyphIcons)