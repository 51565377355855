import { sendProviderSearchEvent } from '../scripts/modules/utmTracking.js'
import '../animations/Loader.js'
import './ZeroIcon.js'

class ZeroModal extends HTMLElement {
    constructor() {
        super()
        this.attachShadow({mode: 'open'})
        this.shadowRoot.innerHTML = this.render()
    }

    connectedCallback() {
        this.openModal()
        this.closeModal()
        this.listen()
    }

    static get observedAttributes() {
        return ['data-show']
    }

    attributeChangedCallback(name, oldVal, newVal) {
        if(name === 'data-show' && JSON.parse(this.dataset.show)) {
            this.injectIframe()
            this.shadowRoot.querySelector('[data-modal]').showModal()
            if(!this.dataset.urlTriggered) this.fireEvent()
            return
        }

        this.shadowRoot.querySelector('[data-modal]').close()
    }

    injectIframe() {
        if(this.dataset.iframe) {   
            const frame = document.createElement('iframe')
            frame.src = this.dataset.iframe + this.getSearchParams()
            frame.style.display = 'none'

            this.shadowRoot.querySelector('[data-content]').innerHTML = '<animation-loader></animation-loader>'
            this.shadowRoot.querySelector('[data-content]').append(frame)
            frame.onload = () => { 
                this.shadowRoot.querySelector('animation-loader').remove() 
                frame.style.display = 'block'
            }
        }
    }

    openModal() {
        const openBtn = this.shadowRoot.querySelector('[data-open]')
        openBtn.addEventListener('click', () => {
            this.dataset.show = true
            this.dispatchEvent(new CustomEvent('modalOpen', { bubbles: true }))
        })
    }

    closeModal() {
        this.shadowRoot.querySelector('[data-modal]').addEventListener('click', (e) => {
            const dialogDims = this.shadowRoot.querySelector('[data-modal]').getBoundingClientRect()
            if(
                e.target.hasAttribute('data-close') ||
                e.clientX < dialogDims.left ||
                e.clientX > dialogDims.right ||
                e.clientY < dialogDims.top ||
                e.clientY > dialogDims.bottom
            ) {
                this.dataset.show = false
                this.removeAttribute('data-url-triggered')
                this.dispatchEvent(new CustomEvent('modalClosed', { bubbles: true }))
            }
        })
    }

    getSearchParams() {
        const params = new URLSearchParams(window.location.search)
        let path = ''
        let queryString = `?source=main-website&zipcode=${params.get('zipcode')}&distance=${params.get('distance')}&query=${params.get('query')}`

        if(params.get('ps') === 'search') {
            path = 'search/'
        }

        if(params.get('ps') === 'listing') {
            path = 'providers/'
        }

        return (params.get('ps')) ? path + queryString : path + '?source=main-website'
    }

    fireEvent() {
        if(this.dataset.type === 'provider-search') {
            sendProviderSearchEvent(this.dataset.loc)
        }
    }

    listen() {
        if(!this.hasAttribute('listen')) return
        
        const params = new URLSearchParams(window.location.search)

        if(window.location.hash === '#provider-search' || params.get('ps')) {
            this.dataset.urlTriggered = true
            this.dataset.show = true
            sendProviderSearchEvent('URL Triggered')
        }
    }
 
    render() {
        return String.raw `
            <style>
                * {
                    box-sizing: border-box;
                }

                :host {
                    display: inline;
                }

                @keyframes in {
                    0% {
                        opacity: 0;
                        translate: 0 -10rem;
                    }

                    100% {
                        opacity: 1;
                        translate: 0 0;
                    }
                }

                .open {
                    all: unset;
                    cursor: pointer;
                    display: inline;
                }

                :host([display-block]) .open {
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                .modal {
                    border: none;
                    border-radius: 3rem;
                    box-shadow: 0 1rem 10rem hsl(0 0% 0% / 0.3);
                    height: 90vh;
                    max-height: 90rem;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    width: min(90%, 140rem);
                }

                .modal[open] {
                    animation: in 300ms ease;
                }

                .modal main {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                .modal::backdrop {
                    background: black;
                    opacity: 0.3;
                }

                .modal header {
                    display: flex;
                    padding-bottom: 0.5rem;
                    padding-inline: 1.5rem;
                }

                .close {
                    --size: 3.4rem;
                    all: unset;
                    background: transparent;
                    border: 0.2rem solid var(--neutral-2);
                    border-radius: var(--size);
                    box-sizing: border-box;
                    cursor: pointer;
                    height: var(--size);
                    margin-left: auto;
                    padding: 0;
                    position: relative;
                    transform: rotate(45deg);
                    transition: all 300ms ease;
                    width: var(--size);
                }

                .close::before, .close::after {
                    background-color: var(--neutral-3);
                    border-radius: 0.3rem;
                    content: '';
                    height: 0.3rem;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: scale(1) translate(-50%, -50%);
                    transition: all 300ms ease;
                    width: 1.6rem;
                }

                .close::after {
                    transform: translate(-50%, -50%) rotate(90deg);
                }

                .close:hover,
                .close:focus-visible {
                    background-color: var(--color-2);
                    border-color: var(--color-2);
                }

                .close:hover::before, 
                .close:hover::after,
                .close:focus-visible::before,
                .close:focus-visible::after {
                    background-color: var(--content);
                }

                .modal section {
                    display: grid;
                    flex-grow: 1;
                    overflow: auto;
                    padding: var(--gap-sm);
                    place-items: center;
                }

                .modal section:has(iframe) {
                    padding: 0;
                }

                .modal iframe {
                    border: none;
                    height: 100%;
                    width: 100%;
                }

                @media (max-width: 600px) {
                    .modal {
                        height: 95vh;
                        max-height: auto;
                    }

                    .modal section {
                        padding: 3rem;
                    }
                }
            </style>

            <button class="open" part="focus" data-open tabindex="0">
                <slot name="trigger"></slot>
            </button>

            <dialog class="modal" data-modal>
                <main>
                    <header>
                        <button class="close" part="focus" data-close title="Close dialog (ESC)"></button>
                    </header>

                    <section data-content>
                        <slot></slot>
                    </section>
                </main>
            </dialog>
        `
    }
}

customElements.define('zero-modal', ZeroModal)