import '../../components/ZeroModal'

const header = document.querySelector('.header-main')
const subNav = document.querySelector('.sub-nav')

try {
    document.querySelector('[data-menu-trigger]').addEventListener('click', () => {
        document.querySelector('zero-menu').toggleAttribute('open')
    })
}
catch(err) {}

function keyboardAccessibleDropdowns() {
    const triggers = document.querySelectorAll('.dropdown-toggle, .dropdown-item')
    const navLinks = document.querySelectorAll('.nav-link:not(.dropdown-toggle)')

    triggers.forEach(item => {
        item.addEventListener('focus', (event) => {
            event.target.nextElementSibling?.classList.add('show')
            event.target.parentElement?.classList.add('show')
        })
    })

    navLinks.forEach(item => {
        item.addEventListener('focus', () => {
            document.querySelectorAll('.navbar .show').forEach(item => item.classList.remove('show'))
        })
    })
}

function detectScroll(obj) {
    const subNavMetrics = subNav.getBoundingClientRect()

    if(obj.pos >= subNavMetrics.height) {
        obj.elem.classList.add('sticky')
        return
    }

    obj.elem.classList.remove('sticky')
}

try {
    // Detect scrolling to add background color to header
    window.addEventListener('scroll', event => {
        detectScroll({
            pos: window.scrollY,
            elem: header
        })
    })
    
} //end try catch
catch(err) {}

// Visually Impaired Mode

if(localStorage.getItem('vi')) {
    document.body.classList.add('visually-impaired')
    showMessage()
}

window.addEventListener('keydown', event => {
    if(event.ctrlKey && event.shiftKey && event.code === 'KeyC') toggleVisuallyImpairedMode()
})

document.querySelectorAll('[data-toggle-vi]').forEach(item => item.addEventListener('click', toggleVisuallyImpairedMode))

function toggleVisuallyImpairedMode() {
    document.body.classList.toggle('visually-impaired')
    
    if(document.body.classList.contains('visually-impaired')) {
        showMessage()
        localStorage.setItem('vi', true)
        return
    }

    localStorage.removeItem('vi')
    document.querySelector('[data-vi]').remove()
}

function showMessage() {
    const msg = document.createElement('zero-toast')
    msg.setAttribute('text', 'High contrast is on. Press CTRL + SHIFT + C or click this to turn off.')
    msg.setAttribute('no-mobile', true)
    msg.setAttribute('data-event', 'viMessage')
    msg.dataset.vi = true
    msg.addEventListener('viMessage', () => {
        toggleVisuallyImpairedMode()
    })

    document.body.append(msg)
}

// Enable keyboard accessible navigation dropdown menus

// try {
//     keyboardAccessibleDropdowns()
// }
// catch(err) {
//     console.log(err)
// }


