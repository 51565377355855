import './scripts/modules/navigation'
import './scripts/modules/triggerChat'
import './scripts/modules/key-events'
import './components/ZeroIllustration'
import './components/ZeroIcon'
import './components/ZeroMenu'
import './components/AnimatedSVG'

import { captureParams } from  './scripts/modules/utmTracking'

import './components/Toast'

// Import CSS for Webpack
import './index.css'

// Pull UTM tracking params from URL and store in sessionStorage
captureParams()

// Mounts JS for any given page based on if a script file exists for the page slug (URI)
async function importPageScripts() {
  const eligible = document.querySelector('[data-script]')
  try {
    await import(
      /* webpackChunkName: "[request]" */
      `./scripts/${eligible.dataset.script}.js`)
  }
  catch(err) {
    console.log(err)
  }
}
importPageScripts()

