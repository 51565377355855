// Pass object into function that has key 'elem' with the node for that element. Optional 'margin' key can be passed and must contain string with required margins, i.e. '20px 0px 0px 0px'

const halfHeightTrigger = `0px 0px -${window.innerHeight / 2}px 0px`

function scrollTriggering(obj) {
    const margin = obj?.margin ?? `0px 0px -${window.innerHeight / 4}px 0px`
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach(entry => {
                entry.target.classList.add('scroll-trigger-on')
                
                if(entry.isIntersecting) {
                    entry.target.classList.add('triggered')
                    if(obj?.in) obj.in(entry)
                    return
                }

                if(obj?.out) obj.out(entry)
            });
        },
        {
            rootMargin: margin
        }
    );
    
    observer.observe(obj.elem)
}

export { 
    scrollTriggering,
    halfHeightTrigger
}