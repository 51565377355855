/* -- 
    Attributes
    name: svg file name without prefix and extension
    animate: animation name (slideUp, scaleDown, fadePop)
    no-circle: removes background circle element
    circle-color: change color of circle from default
    repeat: repeats animation continuously
    scroll-triggered: will animate once in viewport
    scale: for scaling artwork, example value: 1.5 
--*/

import { scrollTriggering } from "../scripts/modules/scroll-triggering"

class IllustrativeIcons extends HTMLElement {
    constructor() {
        super().attachShadow({mode: 'open'})
        this.setAttribute('role', 'presentation')
        this.loadAsset()
    }

    async loadAsset() {
        const path = '/wp-content/themes/ZERO/images/illustrative-icons/'
        const file = `illus-${this.getAttribute('name').replace('-anim', '')}.svg`

        this.addStyles()
        this.loadAnimation()

        const res = await fetch(path + file)
        if(!res.ok) return
        const svg = await res.text()

        this.shadowRoot.innerHTML += svg

        // SVG has been added to DOM
        this.manageCircle()
        if(this.hasAttribute('scroll-triggered')) this.scroll()
    }

    addStyles() {
        const style = `
            <style>
                :host {
                    display: flex;
                    width: 100%;
                }

                svg {
                    display: block;
                    scale: 1.35; //crop out space around in viewbox
                    width: 100%;
                }

                :host([scroll-triggered]) #circle,
                :host([scroll-triggered]) #artwork  {
                    opacity: 0;
                }

                #artwork {
                    transform: scale(${(this.hasAttribute('scale')) ? this.getAttribute('scale') : 1});
                }

                @keyframes circleIn {
                    0% {
                        opacity: 0.3;
                        scale: 0.8;
                    }

                    100% {
                        opacity: 100%;
                        scale: 1;
                    }
                }

                :host([animate]) #circle {
                    animation: circleIn 500ms ease;
                    animation-fill-mode: both;
                    opacity: 0;
                    transform-box: fill-box;
                    transform-origin: center;
                }

                @keyframes fadePop {
                    0% { 
                        opacity: 0;
                        scale: 0.4; 
                    }

                    100% { 
                        opacity: 1;
                        scale: 1;
                    }
                }

                :host([animate]) #artwork {
                    animation-name: fadePop;
                    animation-duration: 500ms;
                    animation-delay: 200ms;
                    animation-fill-mode: both;
                    animation-iteration-count: ${(this.hasAttribute('repeat')) ? 'infinite' : 1};
                    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    transform-box: fill-box;
                    transform-origin: center;
                }

                @keyframes slideUp {
                    0% { transform: translateY(120%); }
                    100% { transform: translateY(0); }
                }

                :host([animate="slideUp"]) #artwork {
                    animation-name: slideUp;
                    animation-duration: 500ms;
                    opacity: 1;
                }

                @keyframes scaleDown {
                    0% { 
                        opacity: 0;
                        scale: 1.7; 
                    }

                    100% { 
                        opacity: 1;
                        scale: 1;
                    }
                }

                :host([animate="scaleDown"]) #artwork {
                    animation-name: scaleDown;
                    animation-duration: 500ms;
                }

                :host([no-circle]) svg {
                    scale: 1.5;
                }

            </style>
        `
        this.shadowRoot.innerHTML = style
    }

    async loadAnimation() {
        if(!this.getAttribute('name').match('-anim')) return
        const path = '/wp-content/themes/ZERO/images/illustrative-icons/animation/'
        const file = `illus-${this.getAttribute('name')}.css`
        const res = await fetch(path + file)
        if(!res.ok) return
        const cssAnim = await res.text()
        this.shadowRoot.innerHTML += `<style>${cssAnim}</style>` 
    }

    manageCircle() {
        if(this.hasAttribute('circle-color')) this.shadowRoot.querySelector('#circle').style.fill = this.getAttribute('circle-color')
        if(this.hasAttribute('no-circle')) this.shadowRoot.querySelector('#circle').style.display = 'none'
    }

    scroll() {
        scrollTriggering({
            elem: this,
            in: (entry) => {
                entry.target.setAttribute('animate', true)
            }
        })
    }
}

customElements.define('zero-illus', IllustrativeIcons)