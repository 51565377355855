/* 
    Usage:
    <zero-animation name="{filename}"></zero-animation>

    Note: Animated SVG files live in /images/animations folder, see object element's data attribute below
*/

class AnimatedSVG extends HTMLElement {
    static observedAttributes = ['play']

    constructor() {
        super().attachShadow({mode: 'open'}).innerHTML = this.render()
    }

    attributeChangedCallback() {
        setTimeout(() => this.shadowRoot.innerHTML = this.render(), 250)
    }

    render() {
        return String.raw`
            <style>
                :host {
                    display: flex;
                    width: 100%;
                }

                object {
                    display: block;
                    margin-inline: auto;
                    width: 100%;
                }
            </style>

            <object data="/wp-content/themes/ZERO/images/animations/${this.getAttribute('name')}.svg" type="image/svg+xml"></object>
        `
    }
}

customElements.define('zero-animation', AnimatedSVG)