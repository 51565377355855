const trackedParams = [
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content'
]
const storeName = 'zeroParams'
const obj = {}
const params = new URLSearchParams(window.location.search)

function checkStorage() {
    return window.sessionStorage.getItem(storeName)
}

function storeParams() {
    if(checkStorage()) return
    window.sessionStorage.setItem(storeName, JSON.stringify(obj))
}

function clearParams() {
    if(!checkStorage()) return
    window.sessionStorage.removeItem(storeName)
}

function getAllParams() {
    if(!checkStorage()) return false
    return JSON.parse(window.sessionStorage.getItem(storeName))
}

function getParam(name) {
    if(!checkStorage()) return false
    const obj = JSON.parse(window.sessionStorage.getItem(storeName))
    return obj[name]
}

function captureParams() {
    if(window.location.search && params.get('utm_source')) {
        trackedParams.map(param => {
            obj[param.replace('utm_', '')] = params.get(param)
        })
        storeParams()
    }
}

// GA Event Triggering

function triggerEvent(action, trigger) {
    const name = `${action.replace(/\s/g, '_').toLowerCase()}`
    const params = {
        trigger_source: trigger,
        page_loc: window.location.pathname,
        page_title: document.title
    }

    // Get UTM campaign 
    captureParams()

    if(getParam('source')) {
        params.campaign = getParam('campaign') || ''
        params.source = getParam('source')
        params.medium = getParam('medium') || ''
    }

    try {
        gtag('event', name, params)
    }
    catch(err) {
        console.log(err)
        console.log({
            event: name,
            params: params
        })
    }
}

// Specific methods for sending GA events

function sendLeadGenEvent(action) {
    triggerEvent(action, 'Lead Gen')
}

function sendProviderSearchEvent(locationLabel) {
    triggerEvent('Opened Provider Search', locationLabel)
}

function sendGatedResUnlockEvent() {
    triggerEvent('Unlocked Resource', 'Lead Gen')
}

function sendContactUsEvent() {
    triggerEvent('Submitted Contact Us', 'Contact Us Page')
}

function sendEmailViewEvent(lang = 'English') {
    triggerEvent(`Viewed ${lang} Email`, document.referrer || 'direct')
}

function sendMemberProviderDownloadEvent(trigger) {
    triggerEvent('Downloaded Member Explainer For Providers', trigger)
}

function sendResourceView(type, trigger) {
    triggerEvent(`Clicked ${type} Resource Card`, trigger)
}

export { 
    captureParams, 
    storeParams, 
    clearParams, 
    getAllParams, 
    getParam, 
    sendProviderSearchEvent,
    sendLeadGenEvent,
    sendGatedResUnlockEvent,
    sendContactUsEvent,
    sendEmailViewEvent,
    sendMemberProviderDownloadEvent,
    sendResourceView,
    triggerEvent
}