// Options: Must be object with "yes" callback and "no" callback properties

export function observeVIMode(options, className = 'visually-impaired') {
    const targetNode = document.body
    const config = { attributes: true }

    if(targetNode.classList.contains(className)) {
        options.yes()
    }

    const callback = (mutationList, observer) => {
        mutationList.forEach((mutation) => {
            if(mutation.type === 'attributes' 
            && mutation.attributeName === 'class' 
            && mutation.target.classList.contains(className)) {
                options.yes()
            }

            if(mutation.type === 'attributes' 
            && mutation.attributeName === 'class' 
            && !mutation.target.classList.contains(className)) {
                options.no()
            }
        })
    }

    const observer = new MutationObserver(callback)
    observer.observe(targetNode, config)
}