class Loader extends HTMLElement {
    constructor() {
        super().attachShadow({mode: 'open'}).innerHTML = this.render()
    }

    render() {
        return String.raw`
            <style>
                * {
                    box-sizing: border-box;
                }

                .loader {
                    align-items: center;
                    display: flex;
                    gap: 0.25em;
                    justify-content: center;
                }

                .loader span {
                    --size: 2rem;
                    animation: scaling 2.5s ease-in-out infinite;
                    background-color: var(--color-2);
                    border-radius: var(--size);
                    height: var(--size);
                    opacity: 0.2;
                    transform: scale(0.2);
                    width: var(--size);
                }

                span:nth-child(2) {
                    animation-delay: 0.3s;
                    background-color: var(--color-3);
                }

                span:nth-child(3) {
                    animation-delay: 0.6s;
                    background-color: var(--color-4);
                }

                @keyframes scaling {
                    0%, 100% {
                        opacity: 0.2;
                        transform: scale(0.2);
                    }

                    40% {
                        opacity: 0.5;
                    }

                    50% {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            </style>

            <div class="loader">
                <span></span>
                <span></span>
                <span></span>
            </div>
        `
    }
}

customElements.define('animation-loader', Loader)